<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <div style="width :100%;display: flex; justify-content:space-between;">
              <p>Formularios de Pre-Registro</p>
            
            <span>Total Registros: {{pagination?.total}}</span>
            </div>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="pagination.perPage"
              @row-clicked="goToForm"
              index-column
              clickable-rows
            >
              <template #name="data">
                <td>{{data.item.name}}</td>
              </template>
              <template #email="data">
                <td>{{data.item.email}}</td>
              </template>
              <template #phone="data">
                <td>{{data.item.phone}}</td>
              </template>
 <!--              <template #estado="data">
                <td>
                  <CBadge :color="getBadge(data.item.estado)">{{getEstado(data.item.estado)}}</CBadge>
                </td>
              </template> -->
<!--               <template #actions="data">
                <CButton
                  color="warning"
                  size="sm"
                  class="m-1"
                  @click="updateUser(data.item, $event)"
                >
                  <font-awesome-icon icon="user-edit" />
                </CButton>
                <CButton
                  color="danger"
                  size="sm"
                  class="m-1"
                  @click="deleteUser(data.item.id, $event)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </template> -->
            </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
                          <CPagination
              :active-page.sync="currentPage"
              :pages="Math.ceil(pagination.total / pagination.perPage)"
              align="center"
            />
          </CCardBody>
        </CCard>
      </transition>
    </CCol>

    <div style="max-width: 600px !important;margin: 0 auto !important;">
    <CModal title="Formulario Pre Registro" color="primary" :show.sync="showModal" size="sm" class="modalReg">
        <div style="max-width: 600px !important;margin: 0 auto !important;">
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Nombre: </p> <p style="text-align:left;">{{selectedForm?.name}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Rut: </p><p style="text-align:left;">{{selectedForm?.rut}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Correo Electrónico: </p><p style="text-align:left;">{{selectedForm?.email}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Teléfono: </p><p style="text-align:left;">{{selectedForm?.phone}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>País: </p><p style="text-align:left;">{{selectedForm?.country_data?.name}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Región: </p><p style="text-align:left;">{{selectedForm?.region_data?.region}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Comuna: </p><p style="text-align:left;">{{selectedForm?.comuna_data?.comuna}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Dirección: </p><p style="text-align:left;">{{selectedForm?.address}}</p></div></CListGroupItem>
    <CListGroupItem href="#" color="light"><div style="display:flex;justify-content:space-between; width:100%; max-width: 500px;"><p>Enfermedad Crónica: </p><p style="text-align:left;">{{selectedForm?.disease}}</p></div></CListGroupItem>
        </div>
    </CModal>
    </div>
  </CRow>
</template>

<script>
import Pagination from '../../helpers/Pagination'
import { getCompanyPreregister } from '../../api/forms'

export default {
    components: {Pagination},
    data () {
        return {
        items: [],
        dataLoading: true,
        fields: [
        { key: 'id', label: '#' },
        { key: 'name', label: 'Usuario' },
        { key: 'email', label:'Email' },
        { key: 'phone', label:'Teléfono' },
        { key: 'created_at', label: 'Creada' },
        //{ key: 'actions', label: 'Acciones' }
        ],
      currentPage: 1,
      show: 1,
      confidence: 0,
      pagination: {
        page: 1,
        perPage: 10,
        total: null,
      },
        updatingUser: null,
        showModal: false,
        loading: true,
        selectedForm: null,

      currentPage: 1,
      show: 1,
      confidence: 0,
      pagination: {
        page: 1,
        perPage: 10,
        total: null,
      },
        }
    },
    mounted(){
        this.getForms()
    },
      methods: {
    paginate(page, perPage){
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total
      }
      this.getForms()
    },
    goToForm(form){
        this.showModal = true;
        this.selectedForm = form;
    },
    closeModal(){
      this.showModal = false
      this.getUsers()
    },
    getForms(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page
      }

      const self = this
      this.loading = true
      getCompanyPreregister(data).then(function(resp){
          self.items = resp.users
          self.pagination = {
            page: self.pagination.page,
            perPage: self.pagination.perPage,
            total: resp.users_count
          }
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
  },
      watch: {
    currentPage: function (page) {
      console.log(page);
      this.pagination = {
        page: page,
        perPage: this.pagination.perPage,
        total: this.pagination.total,
      };
      this.getForms()
    },
  },
}
</script>

<style lang="scss">
.modalReg .modal-dialog {
        width: 20px;
    }

</style>